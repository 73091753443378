import React, { useState } from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import Slider from "react-slick";
import $ from "jquery";
import { StaticQuery, graphql } from "gatsby";

const MarketingProduktowInwestycyjnychPage = ({ acfData }) => {
	if (typeof window !== `undefined`) {
		(function ($) {
			$.fn.shuffleLetters = function (prop) {
				var options = $.extend(
					{
						step: 1, // How many times should the letters be changed
						fps: 30, // Frames Per Second
						text: "", // Use this text instead of the contents
						callback: function () {}, // Run once the animation is complete
					},
					prop
				);

				return this.each(function () {
					var el = $(this),
						str = "";

					// Preventing parallel animations using a flag;
					if (el.data("animated")) {
						return true;
					}
					el.data("animated", true);

					if (options.text) {
						str = options.text.split("");
					} else {
						str = el.text().split("");
					}

					// The types array holds the type for each character;
					// Letters holds the positions of non-space characters;

					var types = [],
						letters = [];

					// Looping through all the chars of the string

					for (var i = 0; i < str.length; i++) {
						var ch = str[i];

						if (ch === " ") {
							types[i] = "space";
							continue;
						} else if (/[a-z]/.test(ch)) {
							types[i] = "lowerLetter";
						} else if (/[A-Z]/.test(ch)) {
							types[i] = "upperLetter";
						}

						letters.push(i);
					}

					el.html("");

					// Self executing named function expression:

					(function shuffle(start) {
						// This code is run options.fps times per second
						// and updates the contents of the page element

						var i,
							len = letters.length,
							strCopy = str.slice(0); // Fresh copy of the string

						if (start > len) {
							// The animation is complete. Updating the
							// flag and triggering the callback;

							el.data("animated", false);
							options.callback(el);
							return;
						}

						// All the work gets done here
						for (i = Math.max(start, 0); i < len; i++) {
							// The start argument and options.step limit
							// the characters we will be working on at once

							if (i < start + options.step) {
								// Generate a random character at thsi position
								strCopy[letters[i]] = randomChar(
									types[letters[i]]
								);
							} else {
								strCopy[letters[i]] = "";
							}
						}

						el.text(strCopy.join(""));

						setTimeout(function () {
							shuffle(start + 1);
						}, 2550 / options.fps);
					})(-options.step);
				});
			};

			function randomChar(type) {
				var pool = "";

				if (type === "lowerLetter") {
					pool = "abcdefghijklmnopqrstuvwxyz";
				} else if (type === "upperLetter") {
					pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
				}

				var arr = pool.split("");
				return arr[Math.floor(Math.random() * arr.length)];
			}
		})($);

		$(function () {
			var container = $("#shuffle");
			container.shuffleLetters();

			function shuffle(text) {
				// Shuffle the container with custom text
				container.shuffleLetters({
					text: text,
				});
			}

			var arr = [
				"strategię komunikacji",
				"nazwę inwestycji",
				"logo",
				"identyfikację wizualną",
				"katalogi",
				"stronę internetową",
				"sukces inwestycji",
			];
			var i = 0;
			setInterval(function () {
				shuffle(arr[i++ % arr.length]);
			}, 2000);
		});
	}
	const sliderSettings = {
		dots: false,
		arrows: true,
		infinite: false,
		draggable: true,
		slidesToShow: 1,
		autoplay: false,
		slidesToScroll: 1,
		variableWidth: true,
	};

	//
	const [slider_cat, setSliderCat] = useState(0);

	const sliderLogos = {
		dots: false,
		arrows: true,
		infinite: true,
		draggable: true,
		slidesToShow: 1,
		autoplay: false,
		slidesToScroll: 1,
	};

	return (
		<Layout
			seo={{
				title: "Marketing i reklama produktów inwestycyjnych, condohoteli",
				description:
					"Odkryj najlepszy marketing produktów inwestycyjnych przy wsparciu ekspertów When. Zadbamy o wsparcie Twojej inwestycji od momentu budowania strategii marki i komunikacji, automatyzację procesów sprzedażowych stronę internetową, aż po marketing internetowy. Realizujemy skuteczny marketing dla condohoteli i produktów inwestycyjnych. Zrealizuj z nami kampanie Google Ads i Facebook Ads.",
				lang: "pl",
			}}
		>
			<span className="anchor" id="home"></span>
			<div className="subpage-header subpage-header--single-offer">
				<h1 className="subpage-header__title">
					Marketing produktów <br /> <span>inwestycyjnych</span>
				</h1>
			</div>

			<div className="single-offer-menu-container">
				<div className="row">
					<div className="col-lg-3">
						<Link to="/uslugi/#specjalizacje">
							<div className="back-wrapper">
								<span className="back-wrapper__arrow"></span>
								<p className="back-wrapper__title">Usługi</p>
							</div>
						</Link>
						<p className="nav-title">Specjalizacje:</p>
						<ul>
							<li>
								<Link to="/marketing-dla-ecommerce/">
									Marketing dla <br /> e-commerce
								</Link>
							</li>
							<li>
								<Link to="/marketing-dla-deweloperow/">
									Marketing dla deweloperów
								</Link>
							</li>
							<li className="current">
								<a href="#home">
									Marketing produktów <br /> inwestycyjnych
								</a>
							</li>
							<li>
								<Link to="/marketing-dla-hoteli/">
									Marketing dla hoteli
								</Link>
							</li>
							{/* <li>
                <a href="/">Marketing dla gamingu</a>
              </li> */}
						</ul>
					</div>
				</div>
			</div>

			<section className="single-offer-section-text-left">
				<div className="row">
					<div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
						<div className="row align-items-center">
							<div className="col-lg-6 text-col">
								<h2 className="text-col__title mb-40">
									Wieloletnie <br /> doświadczenie
								</h2>
								<p className="text-col__desc">
									Naszą przygodę z reklamą produktów
									inwestycyjnych rozpoczęliśmy w 2017 roku
									podczas prowadzenia kampanii leadowej pokoi
									i apartamentów condohotelowych obiektu Green
									Mountain Resort w Karpaczu. Kampania miała
									na celu nie tylko dotrzeć do potencjalnych
									inwestorów indywidualnych, ale również ich
									edukację. Forma inwestowania w condohotele
									nie była wtedy jeszcze tak popularna jak
									dotychczas i dla większości użytkowników,
									którzy mieli styczność z naszą reklamą, była
									to nowość. Naszą pierwszą kampanię
									zakończyliśmy niewątpliwym sukcesem - dzięki
									tysiącom pozyskanych leadów w ciągu roku
									zostało sprzedanych 277 apartamentów!
								</p>
							</div>
							<div className="col-lg-6 img-col">
								<img
									className="img-fluid img-col__image"
									src={require("../assets/images/investment-marketing-img-1.jpg")}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-text-right">
				<div className="row">
					<div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
						<div className="row align-items-center">
							<div className="col-lg-6 img-col">
								<img
									className="img-fluid img-col__image"
									src={require("../assets/images/investment-marketing-img-2.jpg")}
									alt=""
								/>
							</div>
							<div className="col-lg-6 text-col">
								<h2 className="text-col__title mb-40">
									Dziesiątki <br />
									kampanii inwestycyjnych
								</h2>
								<p className="text-col__desc">
									Minęły 4 lata, a my wybierając produkty
									inwestycyjne jako jedną z najważniejszych{" "}
									<span>specjalizacji</span> naszej agencji,
									pomogliśmy już dziesiątkom deweloperów i
									zewnętrznym działom sprzedaży, budując swoje
									własne know-how i <span>transformując</span>{" "}
									naszą Grupę ADream w rzetelnego partnera w
									sukcesie. Wspieramy firmy od momentu
									budowania strategii marki i komunikacji,
									wyboru nazwy, identyfikację wizualną,
									katalogi, leadowe strony internetowe,
									automatyzację procesów sprzedażowych po
									marketing internetowy.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-text single-offer-section-text--marketing-prod-inw">
				<div className="row">
					<div className="col-lg-8 offset-lg-3 text-col">
						<h2 className="text-col__title">
							<span>Zaprojektuj z nami:</span> <br />
							<span id="shuffle">strategię marki</span>
						</h2>
					</div>
				</div>
			</section>
			<section className="single-offer-big-slider">
				<div className="row">
					<div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
						<h2 className="single-offer-big-slider__title">
							Nasze realizacje
						</h2>
						<Slider {...sliderSettings}>
							<div>
								<img
									src={require("../assets/images/horizon-slider-1.jpg")}
									alt=""
									className="img-fluid"
								/>
							</div>
							<div>
								<img
									src={require("../assets/images/horizon-slider-2.jpg")}
									alt=""
									className="img-fluid"
								/>
							</div>
							<div>
								<img
									src={require("../assets/images/horizon-slider-3.jpg")}
									alt=""
									className="img-fluid"
								/>
							</div>
						</Slider>
						<Slider {...sliderSettings}>
							<div>
								<img
									src={require("../assets/images/gmr-slider-1.jpg")}
									alt=""
									className="img-fluid"
								/>
							</div>
							<div>
								<img
									src={require("../assets/images/gmr-slider-2.jpg")}
									alt=""
									className="img-fluid"
								/>
							</div>
							<div>
								<img
									src={require("../assets/images/gmr-slider-3.jpg")}
									alt=""
									className="img-fluid"
								/>
							</div>
							<div>
								<img
									src={require("../assets/images/gmr-slider-6.jpg")}
									alt=""
									className="img-fluid"
								/>
							</div>
						</Slider>
					</div>
				</div>
			</section>
			<section className="single-offer-section-clients">
				<div className="row">
					<div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
						<div className="section-header aligncenter">
							<span className="section-header__small-text">
								Wiemy, jak prowadzić kampanie inwestycyjne
							</span>
							<h2 className="section-header__title">
								Zaufali nam
							</h2>
						</div>

						<div className="single-offer-section-clients__nav">
							{acfData.acf.marketing_clients.map(
								(item, index) => (
									<button
										key={index}
										className={
											index === slider_cat
												? "current"
												: ""
										}
										onClick={() => setSliderCat(index)}
									>
										{item?.category}
									</button>
								)
							)}
						</div>

						{acfData.acf.marketing_clients
							?.filter((item, index) => index === slider_cat)
							.map((item, index) => (
								<div key={index}>
									<Slider {...sliderLogos}>
										{item?.slider?.map((node, i) => (
											<div key={i}>
												<div className="row align-items-center justify-content-center">
													{node?.slides?.map(
														(slide, n) => (
															<div
																className="col-md-3 col-sm-4 col-6 client-col"
																key={n}
															>
																<div className="client-col__logo-wrapper">
																	<img
																		className="img-fluid client-col__logo"
																		src={
																			slide
																				.localFile
																				.publicURL
																		}
																		alt=""
																	/>
																</div>
															</div>
														)
													)}
												</div>
											</div>
										))}
									</Slider>
								</div>
							))}

						{/* <div className="row align-items-center justify-content-center">
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/gmr.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/pmr.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/crystal_mountain.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/biala_perla.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/horizon.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/save_invest.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/vortune.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/wb_finance.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/gubalowka.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/infinity.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/szczawnica.jpg")}
									alt=""
								/>
							</div>
							<div className="col-sm-3 col-6 client-col">
								<img
									className="img-fluid client-col__logo"
									src={require("../assets/img/clients/duovita.jpg")}
									alt=""
								/>
							</div>
						</div> */}
					</div>
				</div>
			</section>
			<section className="single-offer-section-text-small">
				<div className="row">
					<div className="col-lg-6 offset-lg-4 text-col">
						<h2 className="text-col__title">
							Pobierz nasze <br />
							<span>portfolio z branży nieruchomości</span> <br />
							i produktów inwestycyjnych
						</h2>
						<div className="text-wrapper--inner-action">
							<a
								href="https://docs.google.com/presentation/d/1z7tzq7dPhaWSLIyh2BgVi4BR-0aGHsRyCcuF1a_rHGg/edit?usp=sharing"
								className="btn btn-line"
								target="_blank"
								rel="noreferrer"
							>
								pobierz <strong>portfolio</strong>
							</a>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-case-study">
				<div className="row">
					<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
						<h2 className="case-col__title">
							Sprawdź nasze przykładowe case <br /> studies
							produktów inwestycyjnych
						</h2>
						<div className="post-wrapper">
							<div className="post-wrapper__thumbnail-container thumbnail-container">
								<div className="rectangle"></div>
								<img
									className="img-fluid thumbnail-container__image image"
									src={require("../assets/images/investment-marketing-case-1.png")}
									alt=""
								/>
							</div>
							<p className="post-wrapper__category">
								CONDOHOTEL - GREEN MOUNTAIN RESORT
							</p>
							<p className="post-wrapper__title">
								Tysiące pozyskanych leadów od inwestorów i 270
								sprzedanych apartamentów w 12 miesięcy dla
								inwestycji condohotelowej w Karpaczu
							</p>
							<p className="post-wrapper__desc">
								Sprawdź, jak nasza kampania Google i Facebook
								Ads przełożyła się na sprzedaż wszystkich
								apartamentów inwestycyjnych Green Mountain
								Resort w Karpaczu
							</p>
							<div className="text-wrapper--inner-action">
								<Link
									to="/case-study/green-mountain-resort/"
									className="btn btn-line"
								>
									więcej
								</Link>
							</div>
						</div>
					</div>
					<div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3 case-col">
						<div className="post-wrapper">
							<div className="post-wrapper__thumbnail-container thumbnail-container">
								<div className="rectangle"></div>
								<img
									className="img-fluid thumbnail-container__image image"
									src={require("../assets/images/investment-marketing-case-3.png")}
									alt=""
								/>
							</div>
							<p className="post-wrapper__category">
								Produkty inwestycyjne, Google ars, Facebook Ads
							</p>
							<p className="post-wrapper__title">
								Ponad <span>3,45 miliona złotych</span>{" "}
								opłaconych zapisów i 407 akcjonariuszy
							</p>
							<p className="post-wrapper__desc">
								Kampanie akcji crowdfundingowych nie mają przed
								nami tajemnic. Zobacz case study z 54-dniowej
								kampanii Google Ads, Facebook Ads oraz YouTube
								emisji akcji STAVA
							</p>
							<div className="text-wrapper--inner-action">
								<Link
									to="/case-study/stava/"
									className="btn btn-line"
								>
									więcej
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-publications">
				<div className="row">
					<div className="col-lg-8 offset-lg-4 pub-col">
						<h2 className="single-offer-section-publications__title">
							Publikacje branżowe
						</h2>
						<div className="row">
							<div className="col-xl-6">
								<div className="single-book">
									<div className="single-book__img-wrapper">
										<img
											className="img-fluid single-book__image"
											src={require("../assets/img/book-sila-optymalizacji.png")}
											alt=""
										/>
									</div>
									<div className="text-container">
										<div>
											<p className="text-container__magazine-name">
												Magazyn Inmag. Numer 1(3)/2020
												(Luty)
											</p>
											<p className="text-container__title">
												Siła optymalizacji. Kampanie
												Google Ads dla inwestycji
												deweloperskich
											</p>
										</div>
										<p className="text-container__author">
											<strong>
												Patrycja Idzińska, <br />
											</strong>
											CEO Agencji WHEN
										</p>
									</div>
								</div>
								<a
									href="https://i.st-nieruchomosci-online.pl/inmag/files/inmag-01-2020.pdf"
									className="btn btn-box single-book__button"
									rel="noreferrer nofollow"
									target="_blank"
								>
									pobierz
								</a>
							</div>
							<div className="col-xl-6">
								<div className="single-book">
									<div className="single-book__img-wrapper">
										<img
											className="img-fluid single-book__image"
											src={require("../assets/img/book-nie-zgaduj.png")}
											alt=""
										/>
									</div>
									<div className="text-container">
										<div>
											<p className="text-container__magazine-name">
												Magazyn INMAG. Numer 2(4)/2020
											</p>
											<p className="text-container__title">
												Nie zgaduj. Testy A/B strony
												inwestycji bez ingerencji
												programisty
											</p>
										</div>
										<p className="text-container__author">
											<strong>
												Patrycja Idzińska, <br />
											</strong>
											CEO Agencji WHEN
										</p>
									</div>
								</div>
								<a
									href="https://i.st-nieruchomosci-online.pl/inmag/files/inmag-02-2020.pdf"
									className="btn btn-box single-book__button"
									rel="noreferrer nofollow"
									target="_blank"
								>
									pobierz
								</a>
							</div>
							<div className="col-xl-6">
								<div className="single-book">
									<div className="single-book__img-wrapper">
										<img
											className="img-fluid single-book__image"
											src={require("../assets/img/book-kreatywnosc.png")}
											alt=""
										/>
									</div>
									<div className="text-container">
										<div>
											<p className="text-container__magazine-name">
												Magazyn INMAG. Numer 3(5)/2020
												(wrzesień)
											</p>
											<p className="text-container__title">
												Kreatywność w służbie sprzedaży.
												Jak wyróżnić się na tle
												konkurencji i dać się
												zapamiętać?
											</p>
										</div>
										<p className="text-container__author">
											<strong>
												Wojciech Kmiecik, <br />
											</strong>
											Creative Director ADream
										</p>
									</div>
								</div>
								<a
									href="https://i.st-nieruchomosci-online.pl/inmag/files/inmag-03-2020.pdf"
									className="btn btn-box single-book__button"
									rel="noreferrer nofollow"
									target="_blank"
								>
									pobierz
								</a>
							</div>
							<div className="col-xl-6">
								<div className="single-book">
									<div className="single-book__img-wrapper">
										<img
											className="img-fluid single-book__image"
											src={require("../assets/img/book-instrukcja.png")}
											alt=""
										/>
									</div>
									<div className="text-container">
										<div>
											<p className="text-container__magazine-name">
												Magazyn INMAG. Numer 4(6)/2020
											</p>
											<p className="text-container__title">
												Serwis www główny specjalista
												ds. Wsparcia działu sprzedaży
											</p>
										</div>
										<p className="text-container__author">
											<strong>
												Wojciech Kmiecik, <br />
											</strong>
											Creative Director ADream
										</p>
									</div>
								</div>
								<a
									href="https://i.st-nieruchomosci-online.pl/inmag/files/inmag-04-2020.pdf"
									className="btn btn-box single-book__button"
									rel="noreferrer nofollow"
									target="_blank"
								>
									pobierz
								</a>
							</div>
							<div className="col-xl-6">
								<div className="single-book">
									<div className="single-book__img-wrapper">
										<img
											className="img-fluid single-book__image"
											src={require("../assets/img/book-zaufanie.png")}
											alt=""
										/>
									</div>
									<div className="text-container">
										<div>
											<p className="text-container__magazine-name">
												Magazyn INMAG. Numer 3(9)/2021
											</p>
											<p className="text-container__title">
												Nie unikaj krytyki, czyli
												świadomy dialog z klientem
											</p>
										</div>
										<p className="text-container__author">
											<strong>
												Aleksandra Żurek, <br />
											</strong>
											Social Media Specialist
										</p>
									</div>
								</div>
								<a
									href="https://i.st-nieruchomosci-online.pl/inmag/files/inmag-03-2021-1630964480.pdf"
									className="btn btn-box single-book__button"
									rel="noreferrer nofollow"
									target="_blank"
								>
									pobierz
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="single-offer-section-contact">
				<div className="form-wrapper">
					<h2 className="single-offer-section-contact__title mb-40">
						Skontaktuj się z nami! <br />
						Wypełnij <span>formularz</span>
					</h2>
					<ContactFormDark locales={locales["pl"]} />
				</div>
				<img
					className="img-fluid single-offer-section-contact__hand"
					src={require("../assets/images/home-contact-hand-right.png")}
					alt=""
				/>
			</section>
		</Layout>
	);
};

export default () => (
	<StaticQuery
		query={graphql`
			{
				acfData: wordpressPage(
					id: { eq: "09d91a66-cbf3-5f11-9dfc-1a7fea1ef64e" }
				) {
					acf {
						marketing_clients {
							category
							slider {
								slides {
									localFile {
										publicURL
									}
								}
							}
						}
					}
				}
			}
		`}
		render={(data) => <MarketingProduktowInwestycyjnychPage {...data} />}
	/>
);
